const Footer = () => {
  return (
    <footer className="bg-[#1E293B] text-light py-12">
      <div className="max-w-7xl mx-auto px-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Services */}
        <div>
          <h4 className="text-white font-semibold mb-4">SERVICES</h4>
          <ul className="space-y-2">
            <li>
              <a href="#" className="hover:text-primary">
                Web hosting
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primary">
                Object Storage (CDN)
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primary">
                Web development
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primary">
                Game development
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primary">
                PC building & repair
              </a>
            </li>
          </ul>
        </div>

        {/* Support */}
        <div>
          <h4 className="text-white font-semibold mb-4">SUPPORT</h4>
          <ul className="space-y-2">
            <li>
              <a href="#" className="hover:text-primary">
                Knowledge base
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primary">
                Submit ticket
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-primary">
                Contact us
              </a>
            </li>
          </ul>
        </div>

        {/* Logo and Branding */}
        <div className="flex flex-col items-center lg:items-end justify-end">
          <div className="flex items-center space-x-4 mb-20 mt-6 lg:mt-0">
            <img src="/images/pulsheberg.png" alt="Stripe" className="h-8" />
            <img src="/images/stripe.svg" alt="Stripe" className="h-8" />
          </div>

          <div className="flex items-center lg:items-center justify-center lg:justify-end w-full">
            <img src="/logo256.png" alt="sCloud" className="w-16" />
            <p className="text-light">© sCloud, {new Date().getFullYear()}</p>
          </div>
          <span className="italic text-xs">
            sCloud is a registered trademark of Lucas Guiot.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
