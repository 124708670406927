import { useState, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDropzone } from "react-dropzone";

interface FileWithPreview extends File {
  preview?: string;
}

interface UploadedFile {
  name: string;
  url: string;
}

interface Notification {
  id: number;
  type: "success" | "error";
  message: string;
}

interface FaqItem {
  question: string;
  answer: string;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_FILES = 5;
const API_URL = "https://cdn.sacul.cloud/scloud-uploads";

const FAQ_ITEMS: FaqItem[] = [
  {
    question: "Is this service free?",
    answer:
      "Yes! Our CDN image hosting service is completely free and will always remain free for everyone to use.",
  },
  {
    question: "Are there any rate limits?",
    answer:
      "Yes, there is a rate limit of 10 file uploads per minute to ensure fair usage for all users.",
  },
  {
    question: "How long are the images stored?",
    answer:
      "Images are stored for as long as they are being accessed. However, they will be automatically deleted after 1 month of inactivity (no access to the file).",
  },
  {
    question: "What file types are supported?",
    answer:
      "We currently support common image formats including PNG, JPG, JPEG, GIF, and WEBP. Each file must be under 10MB in size.",
  },
];

const FileUpload = () => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [openFaqIndex, setOpenFaqIndex] = useState(0);

  const showNotification = (type: "success" | "error", message: string) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, type, message }]);
    setTimeout(() => {
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== id)
      );
    }, 5000);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (files.length + acceptedFiles.length > MAX_FILES) {
        showNotification(
          "error",
          `Maximum ${MAX_FILES} files allowed per upload`
        );
        return;
      }

      const validFiles = acceptedFiles.filter((file) => {
        if (file.size > MAX_FILE_SIZE) {
          showNotification(
            "error",
            `${file.name} is too large. Maximum size is 10MB`
          );
          return false;
        }
        return true;
      });

      const filesWithPreviews = validFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles((prev) => [...prev, ...filesWithPreviews]);
    },
    [files.length]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp"],
    },
  });

  const removeFile = (index: number) => {
    setFiles((prev) => {
      const newFiles = [...prev];
      URL.revokeObjectURL(newFiles[index].preview || "");
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleUpload = async () => {
    if (files.length === 0) return;

    setUploading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      const response = await fetch(API_URL, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error(
            data.error || "Too many uploads. Please try again later."
          );
        }
        throw new Error("Upload failed");
      }

      if (!data.success) {
        throw new Error(data.error || "Upload failed");
      }

      setUploadedFiles(
        files.map((file, index) => ({
          name: file.name,
          url: data.urls[index],
        }))
      );

      showNotification("success", "Files uploaded successfully!");
      setFiles([]);
    } catch (error) {
      showNotification(
        "error",
        error instanceof Error
          ? error.message
          : "Upload failed. Please try again."
      );
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  useEffect(() => {
    return () =>
      files.forEach((file) => URL.revokeObjectURL(file.preview || ""));
  }, [files]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 text-white py-20 px-4 sm:px-6 lg:px-8 relative overflow-hidden"
    >
      {/* Notifications */}
      <div className="fixed bottom-4 right-4 z-50 space-y-2">
        <AnimatePresence>
          {notifications.map((notification) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, x: 20, scale: 0.95 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: 20, scale: 0.95 }}
              className={`px-4 py-2 rounded-lg shadow-lg backdrop-blur-sm flex items-center gap-2 ${
                notification.type === "success"
                  ? "bg-green-500/20 text-green-200"
                  : "bg-red-500/20 text-red-200"
              }`}
            >
              <span className="text-xl">
                {notification.type === "success" ? "✓" : "✕"}
              </span>
              {notification.message}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-20 left-20 w-72 h-72 bg-blue-500/30 rounded-full filter blur-[100px]" />
        <div className="absolute bottom-20 right-20 w-72 h-72 bg-purple-500/30 rounded-full filter blur-[100px]" />
      </div>

      <div className="max-w-4xl mx-auto relative">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-center mb-12"
        >
          <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text pb-2">
            Upload Images to sCloud CDN
          </h1>
          <p className="text-slate-300">
            Securely store and deliver your images with blazing speed
          </p>
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="backdrop-blur-lg bg-white/10 rounded-2xl p-8 shadow-2xl border border-white/20"
        >
          <div
            {...getRootProps()}
            className={`p-10 border-2 border-dashed rounded-xl text-center cursor-pointer transition-all duration-300
              ${
                isDragActive
                  ? "border-blue-400 bg-blue-500/10 scale-[0.99] transform"
                  : "border-white/20 hover:border-blue-400 hover:bg-white/5"
              }`}
          >
            <input {...getInputProps()} />
            <div className="space-y-4">
              <motion.div
                animate={{
                  scale: isDragActive ? 1.1 : 1,
                  rotate: isDragActive ? 180 : 0,
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="text-5xl mb-4"
              >
                {isDragActive ? "🎯" : "📁"}
              </motion.div>
              {isDragActive ? (
                <p className="text-lg text-blue-400 font-medium">
                  Drop them here!
                </p>
              ) : (
                <>
                  <p className="text-lg font-medium">
                    Drag & drop your files here, or click to browse
                  </p>
                  <p className="text-sm text-slate-400">
                    Supported formats: PNG, JPG, JPEG, GIF, WEBP (Max 10MB)
                  </p>
                </>
              )}
            </div>
          </div>

          {files.length > 0 && (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="mt-8"
            >
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <span>Selected Files</span>
                <span className="bg-blue-500/20 text-blue-400 px-2 py-1 rounded-full text-sm">
                  {files.length}
                </span>
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {files.map((file, index) => (
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 20, opacity: 0 }}
                    transition={{ delay: index * 0.1 }}
                    key={index}
                    className="group relative backdrop-blur-sm bg-white/5 rounded-lg border border-white/10 overflow-hidden"
                  >
                    <img
                      src={file.preview}
                      alt={file.name}
                      className="w-full h-32 object-cover"
                    />
                    <div className="p-3">
                      <div className="flex items-center justify-between">
                        <span className="truncate font-medium flex-1">
                          {file.name}
                        </span>
                        <span className="text-sm text-slate-400 bg-white/5 px-2 py-0.5 rounded-full ml-2">
                          {(file.size / 1024 / 1024).toFixed(2)} MB
                        </span>
                      </div>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => removeFile(index)}
                      className="absolute top-2 right-2 w-6 h-6 flex items-center justify-center rounded-full bg-red-500/80 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ✕
                    </motion.button>
                  </motion.div>
                ))}
              </div>

              <div className="mt-6">
                {uploading && (
                  <div className="mb-4">
                    <div className="h-2 w-full bg-white/10 rounded-full overflow-hidden">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: `${uploadProgress}%` }}
                        className="h-full bg-gradient-to-r from-blue-500 to-purple-500"
                      />
                    </div>
                    <p className="text-center text-sm text-slate-400 mt-2">
                      Uploading... {uploadProgress}%
                    </p>
                  </div>
                )}

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleUpload}
                  disabled={uploading}
                  className={`w-full py-4 px-6 rounded-xl font-medium transition-all duration-300
                    ${
                      uploading
                        ? "bg-slate-700/50 cursor-not-allowed"
                        : "bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 shadow-lg hover:shadow-blue-500/25"
                    }`}
                >
                  <span className="flex items-center justify-center gap-2">
                    {uploading ? (
                      <>
                        <svg
                          className="animate-spin h-5 w-5"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="none"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        <span>Uploading...</span>
                      </>
                    ) : (
                      <>
                        <span>Upload Files</span>
                        <span>→</span>
                      </>
                    )}
                  </span>
                </motion.button>
              </div>
            </motion.div>
          )}

          {uploadedFiles.length > 0 && (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="mt-8 pt-8 border-t border-white/10"
            >
              <h3 className="text-xl font-semibold mb-4">Uploaded Files</h3>
              <div className="space-y-4">
                {uploadedFiles.map((file, index) => (
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    key={index}
                    className="bg-white/5 rounded-lg overflow-hidden"
                  >
                    <div className="p-4 flex flex-col sm:flex-row items-start sm:items-center gap-4">
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                      <div className="flex-1 min-w-0 w-full">
                        <p className="font-medium truncate">{file.name}</p>
                        <div className="mt-1 flex flex-col sm:flex-row items-stretch sm:items-center gap-2">
                          <input
                            type="text"
                            value={file.url}
                            readOnly
                            className="w-full flex-1 bg-white/5 text-sm px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(file.url);
                              showNotification(
                                "success",
                                "URL copied to clipboard!"
                              );
                            }}
                            className="px-3 py-1 text-sm bg-blue-500/20 hover:bg-blue-500/30 text-blue-300 rounded-lg transition-colors whitespace-nowrap"
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </motion.div>

        {/* FAQ Section */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mt-16"
        >
          <h2 className="text-3xl font-bold mb-8 text-center bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {FAQ_ITEMS.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 overflow-hidden"
              >
                <button
                  onClick={() =>
                    setOpenFaqIndex(openFaqIndex === index ? -1 : index)
                  }
                  className="w-full px-6 py-4 flex items-center justify-between text-left hover:bg-white/5 transition-colors"
                >
                  <span className="font-medium">{item.question}</span>
                  <motion.span
                    animate={{ rotate: openFaqIndex === index ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                    className="text-blue-400"
                  >
                    ↓
                  </motion.span>
                </button>
                <AnimatePresence>
                  {openFaqIndex === index && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="overflow-hidden"
                    >
                      <div className="px-6 py-4 text-slate-300 border-t border-white/10">
                        {item.answer}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FileUpload;
