import { useState } from "react";

import { HeroButton } from "./Buttons";
import { Slider } from "./Slider";

const CDNPlans = () => {
  const fixedPlans = [
    {
      plan: "Starter",
      storage: "250 GB",
      bandwidth: "500 GB",
      requests: "1M GET/PUT",
      price: "€20/month",
    },
    {
      plan: "Pro",
      storage: "500 GB",
      bandwidth: "750 GB",
      requests: "10M GET/PUT",
      price: "€45/month",
    },
    {
      plan: "Business",
      storage: "1 TB",
      bandwidth: "1 TB",
      requests: "25M GET/PUT",
      price: "€70/month",
    },
    {
      plan: "Enterprise",
      storage: "5+ TB",
      bandwidth: "1+ TB",
      requests: "50M+ GET/PUT",
      price: "Custom Pricing",
    },
  ];

  const [storage, setStorage] = useState(1000); // GB
  const [bandwidth, setBandwidth] = useState(1000); // GB
  const [requests, setRequests] = useState(10); // Million requests
  const [backup, setBackup] = useState(0); // Backup option (amount of backups)
  const [duration, setDuration] = useState("monthly"); // Monthly or Annually

  const calculatePrice = () => {
    const storagePrice = storage * 0.01; // €0.01/GB
    const bandwidthPrice = bandwidth * 0.05; // €0.05/GB
    const requestsPrice = requests * 0.5; // €0.50/million requests
    const backupPrice = backup * 5; // €5.00/backup

    const basePrice = 10; // Base price
    const totalPrice =
      storagePrice + bandwidthPrice + requestsPrice + basePrice + backupPrice;
    return duration === "annually"
      ? (totalPrice * 0.9).toFixed(2)
      : totalPrice.toFixed(2); // 10% discount for annual
  };

  return (
    <section className="bg-dark py-16 text-light">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl lg:text-4xl font-bold text-white text-center mb-2">
          CDN Pricing
        </h2>
        <p className="text-light text-center mb-12">
          sCloud's Content Delivery Network (CDN) offers a range of fixed plans
          and dynamic pricing based on your requirements. Choose the best plan
          that suits your needs.
        </p>

        {/* Fixed Plans */}
        <h3 className="text-2xl font-semibold text-white text-left mb-2">
          Fixed Plans
        </h3>
        <p className="text-light text-left mb-4">
          Choose from our fixed plans that suit your needs. Our fixed plans are
          specially designed for small to medium-sized businesses using the most
          advanced technologies to deliver the best performance and reliability.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {fixedPlans.map((plan, index) => (
            <div
              key={index}
              className="bg-[#1E293B] rounded-lg p-6 shadow-lg hover:shadow-xl transition-transform hover:scale-105"
            >
              <h3 className="text-xl font-semibold text-white mb-4">
                {plan.plan}
              </h3>
              <p className="text-light mb-2">Storage: {plan.storage}</p>
              <p className="text-light mb-2">Bandwidth: {plan.bandwidth}</p>
              <p className="text-light mb-4">Requests: {plan.requests}</p>
              <p className="text-primary text-lg font-bold">{plan.price}</p>
            </div>
          ))}
        </div>

        {/* Dynamic Pricing */}
        <h3 className="text-2xl font-semibold text-white text-left mb-2">
          🔥 Dynamic Pricing
        </h3>
        <p className="text-light text-left mb-4">
          Customize your plan based on your requirements. Use the sliders to
          select the amount of storage, bandwidth, and requests you need. The
          total price will be calculated based on your selections.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-[#1E293B] rounded-lg p-6 shadow-lg">
            <h3 className="text-2xl font-semibold text-white mb-6">
              Configuration
            </h3>

            {/* Sliders */}
            <div className="mb-6">
              <label className="block text-light mb-2">Storage (GB)</label>
              <Slider
                min={250}
                max={5000}
                step={250}
                value={storage}
                onChange={(value) => setStorage(value)}
              />
              <p className="text-primary mt-2">{storage} GB</p>
            </div>

            <div className="mb-6">
              <label className="block text-light mb-2">Bandwidth (GB)</label>
              <Slider
                min={500}
                max={10000}
                step={500}
                value={bandwidth}
                onChange={(value) => setBandwidth(value)}
              />

              <p className="text-primary mt-2">{bandwidth} GB</p>
            </div>

            <div className="mb-6">
              <label className="block text-light mb-2">
                Requests (Million)
              </label>
              <Slider
                min={1}
                max={100}
                step={1}
                value={requests}
                onChange={(value) => setRequests(value)}
              />
              <p className="text-primary mt-2">{requests} Million</p>
            </div>

            <div className="mb-6">
              <label className="block text-light mb-2">Backup (Number)</label>
              <Slider
                min={0}
                max={10}
                step={1}
                value={backup}
                onChange={(value) => setBackup(value)}
              />
              <p className="text-primary mt-2">{backup} Backup</p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            {/* Pricing Summary */}
            <div className="bg-[#1E293B] rounded-lg p-6 shadow-lg">
              <h3 className="text-2xl font-semibold text-white mb-6">
                Summary
              </h3>
              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Duration</span>
                <div className="flex space-x-4">
                  <button
                    className={`px-4 py-2 rounded-full ${
                      duration === "monthly"
                        ? "bg-primary text-white"
                        : "bg-[#1E293B] text-light"
                    }`}
                    onClick={() => setDuration("monthly")}
                  >
                    Monthly
                  </button>
                  <button
                    className={`px-4 py-2 rounded-full ${
                      duration === "annually"
                        ? "bg-primary text-white"
                        : "bg-[#1E293B] text-light"
                    }`}
                    onClick={() => setDuration("annually")}
                  >
                    Annually (-10%)
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Base Price</span>
                <span className="text-primary">€10.00</span>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Storage</span>
                <span className="text-primary">
                  €{(storage * 0.01).toFixed(2)}
                </span>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Bandwidth</span>
                <span className="text-primary">
                  €{(bandwidth * 0.05).toFixed(2)}
                </span>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Requests</span>
                <span className="text-primary">
                  €{(requests * 0.5).toFixed(2)}
                </span>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Backup</span>
                <span className="text-primary">€{(backup * 5).toFixed(2)}</span>
              </div>
            </div>

            {/* Total Price */}
            <div className="bg-[#1E293B] rounded-lg p-6 shadow-lg">
              <h3 className="text-2xl font-semibold text-white mb-6">
                Total Price
              </h3>
              <div className="flex items-center justify-between mb-4">
                <span className="text-light">Total</span>
                <span className="text-primary">{calculatePrice()} €</span>
              </div>
              <HeroButton
                fullWidth
                onClick={() => {
                  alert("You clicked the button!");
                }}
              >
                Get Started
              </HeroButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CDNPlans;
