import Hero from "../components/Hero";
import Features from "../components/Features";
import WhyChooseUs from "../components/WhyChooseUs";

function Home() {
  return (
    <div className="App">
      <Hero />
      <Features />
      <WhyChooseUs />
    </div>
  );
}

export default Home;
