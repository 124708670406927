import { motion } from "framer-motion";

import { HeroButton } from "./Buttons";

const CDNHero = () => {
  return (
    <section className="flex flex-col lg:flex-row items-center justify-center text-center lg:text-left bg-dark text-light py-16 px-8 lg:px-24">
      {/* Animated Text Section */}
      <motion.div
        className="flex-1 m-0"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-4xl lg:text-5xl font-bold text-white">
          <span className="block leading-snug">Advanced solutions</span>
          <span className="block leading-snug">to accelerate your</span>
          <span className="block leading-snug">content delivery</span>
        </h1>

        <p className="mt-4 text-lg text-light max-w-xl">
          Our Content Delivery Network (CDN) is designed to deliver your content
          with the lowest latency, highest transfer speeds, and maximum
          security.
        </p>
        <div className="mt-8 flex gap-4 justify-center lg:justify-start">
          <HeroButton
            onClick={() => {
              alert("You clicked the button!");
            }}
          >
            Let’s get started
          </HeroButton>
        </div>
      </motion.div>

      {/* Animated Image Section */}
      <motion.div
        className="flex-1 mt-8 lg:mt-0 flex justify-center"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <img
          src="/images/hero.png"
          alt="Hosting technology illustration"
          className="w-full max-w-xl"
        />
      </motion.div>
    </section>
  );
};

export default CDNHero;
